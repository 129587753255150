import { Variants } from "framer-motion";

export const pageVariants: Variants = {
  hidden: { x: 0.3 },
  enter: { x: 1 },
  exit: { x: 0.3 },
};

export const imageVariants: Variants = {
  hidden: {
    opacity: 0,
    y: 0,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  onscreen: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 1,
    },
  },
};

export const menuVariants = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    x: -100,
    opacity: 0,
    transition: {
      x: { stiffness: 1000 },
    },
  },
};

export const menuListVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.4 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const cardVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: -50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export const cardListVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.6, delay: 1 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: 1 },
  },
};

export const animatedCharactersVariants = {
  hidden: {
    y: -500,
    color: "white",
    transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.85 },
  },
  visible: {
    y: -200,
    x: 20,
    color: "#B7734A",
    transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.75 },
  },
};
