import { FC } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { motion } from "framer-motion";
import { menuVariants } from "../../utils/variants";

type Props = {
  path: string;
  title: string;
  isHeader?: boolean;
};

export const HeaderLink: FC<Props> = ({ title, path, isHeader }) => {
  const { asPath } = useRouter();

  return (
    <motion.li
      className={`block p-1 xl:p-2 relative font-title uppercase text-sm xl:text-lg ${asPath !== `/${path}` ? 'link-underline link-underline-brown cursor-pointer' : 'pointer-events-none'} ${asPath === `/${path}`
          ? `font-bold ${isHeader ? "text-light-gray" : "text-white"}`
          : `${isHeader ? "text-gray" : "text-brown"}`
        }`}
      {...(!isHeader && { variants: menuVariants })}
    >
      <Link href={`/${path}`} passHref>
        {title}
      </Link>
    </motion.li>
  );
};
