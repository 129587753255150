import { FC, useEffect, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { motion } from "framer-motion";
import { AiOutlineClose } from "react-icons/ai";
import { HeaderLink } from "../header-link";
import { FiMenu, FiArrowLeft } from "react-icons/fi";
import { menuListVariants } from "../../utils/variants";
import { headerRoutes } from "../../config/routes";

export const Header: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const router = useRouter();

  const handleBurgerClick = () => setIsMenuOpen((value) => !value);

  const handleGoBack = () => router.back();

  useEffect(() => {
    const closeMenu = () => setIsMenuOpen(false);
    router.events.on("routeChangeStart", closeMenu);

    return () => {
      router.events.off("routeChangeStart", closeMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <header className="fixed w-full h-18 flex flex-col items-center bg-beige z-20">
        <div className="h-18 flex lg:flex-row lg:justify-between w-5/6 sm:w-4/5 lg:w-3/4 xl:w-4/5 2xl:w-3/5">
          <div className="basis-1/3 h-18 flex justify-start items-center">
            {router.asPath !== "/" ? 
              <>
                <FiArrowLeft
                  className="text-2xl text-black cursor-pointer z-40 lg:hidden"
                  onClick={handleGoBack}
                />
                <Link href="/" passHref={true}>
                  <a className="w-18 h-18 cursor-pointer relative hidden lg:flex before:content-[' '] before:bg-[url('/logo.png')] before:bg-center before:bg-contain before:bg-no-repeat before:absolute before:w-24 before:h-24 before:top-4"><span className="sr-only">Home</span></a>
                </Link>
              </>
              : 
              <Link href="/" passHref={true}>
                <a className="w-18 h-18 cursor-pointer relative hidden lg:flex before:content-[' '] before:bg-[url('/logo.png')] before:bg-center before:bg-contain before:bg-no-repeat before:absolute before:w-24 before:h-24 before:top-4"><span className="sr-only">Home</span></a>
              </Link>
            }
          </div>
          <div className="basis-1/3 h-18 flex justify-center items-center lg:hidden">
            <Link href="/" passHref={true}>
              <a className="w-18 h-18 cursor-pointer relative before:content-[' '] before:bg-[url('/logo.png')] before:bg-center before:bg-contain before:bg-no-repeat before:absolute before:w-24 before:h-24 before:top-4 before:-left-3"><span className="sr-only">Home</span></a>
            </Link>
          </div>
          <div className="basis-1/3 h-18 flex flex-row justify-end items-center">
            <>
              <FiMenu
                className={`${isMenuOpen
                  ? "-rotate-45 translate-x-20"
                  : "rotate-0 translate-x-0"
                  } text-2xl text-black cursor-pointer transition-all duration-300 ease-in-out z-50 lg:hidden`}
                onClick={handleBurgerClick}
              />
              <ul className="hidden lg:flex flex-row whitespace-nowrap gap-2">
                {headerRoutes.slice(1).map((route, index) => {
                  return <HeaderLink key={index} {...route} isHeader />;
                })}
              </ul>
            </>
          </div>
        </div>
      </header>
      <div className={`${isMenuOpen ? "translate-x-0" : "translate-x-full"} flex flex-col items-center fixed transition-all duration-500 ease-in-out z-50 w-full z-50 inset-0 bg-beige overflow-y-hidden scrollbar-hide before:content-[' '] before:bg-[url('/logo.png')] before:bg-center before:bg-contain before:bg-no-repeat before:absolute before:w-24 before:h-24 before:bottom-20`}>
        <div
          className={`flex flex-col w-5/6 sm:w-4/5 lg:w-3/4 xl:w-4/5 2xl:w-3/5`}
        >
          <div className="flex justify-end items-center h-18">
            <AiOutlineClose
              className={`${isMenuOpen
                ? "rotate-0 translate-x-0"
                : "rotate-45 -translate-x-100"
                } text-2xl text-brown cursor-pointer transition-all duration-300 ease-in-out`}
              onClick={handleBurgerClick}
            />
          </div>
          <motion.nav initial={false} animate={isMenuOpen ? "open" : "closed"}>
            <motion.ul
              className="flex flex-col items-center justify-center w-full"
              variants={menuListVariants}
            >
              {headerRoutes.map((route, index) => {
                return <HeaderLink key={index} {...route} />;
              })}
            </motion.ul>
          </motion.nav>
        </div>
      </div>
    </>
  );
};
