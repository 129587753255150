import Link from "next/link";
import { FiInstagram } from "react-icons/fi";

export const Footer = () => {
  return (
    <footer className="flex flex-col items-center w-full z-20 bg-gray h-12">
      <div className="grid grid-cols-2 py-3 w-5/6 sm:w-4/5 lg:w-3/4 xl:w-4/5 2xl:w-3/5">
        <div className="flex justify-start items-center text-sm text-white">
          <a
            href="https://www.instagram.com/aplaer_"
            rel="noreferrer"
            target="_blank"
            className="mr-5"
          >
            <FiInstagram aria-hidden="true" focusable="false" />
            <span className="sr-only">Instagram</span>
          </a>
          <Link href="/contacte" passHref>info@aplaer.com</Link>
        </div>
        <div className="flex justify-end items-center text-sm text-white">
          <a href="https://www.alexbarba.dev" rel="noreferrer" target="_blank">
            © àlex barba {new Date().getFullYear()}
          </a>
        </div>
      </div>
    </footer>
  );
};
