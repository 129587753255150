export const headerRoutes = [
  { title: "Inici", path: "" },
  { title: "Sobre Aplaer", path: "sobre-aplaer" },
  {
    title: "Tallers",
    path: "tallers",
  },
  { title: "Formacions", path: "formacions" },
  { title: "Projectes", path: "projectes" },
  { title: "Materials", path: "materials" },
  // { title: "Parlem-ne", path: "parlem-ne" },
  { title: "Contacte", path: "contacte" },
];
