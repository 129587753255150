import { DefaultSeo } from "next-seo";
import { useRouter } from "next/router";

const BASE_URL = "https://www.aplaer.com";

export const DefaultSeoComponent = () => {
  const { asPath } = useRouter();

  return (
    <DefaultSeo
      titleTemplate="%s | Aplaer"
      defaultTitle="Aplaer"
      description="Aplaer és un projecte d'educació sexual integral d'acompanyament a les sexualitats. Oferim espais de debat i coneixement vers la salut sexual i reproductiva. Treballem des d'una perspectiva feminista posant al centre el plaer i les cures. Desenvolupem tallers i formacions dirigides a infants, joves, famílies i comunitat educativa. A través de la vivencialitat i la participació apostem per ampliar la mirada de la vivència sexual des de la diversitat i la coeducació."
      canonical={`${BASE_URL}${asPath}`}
      additionalMetaTags={[
        {
          property: "dc:creator",
          content: "Àlex Barba",
        },
        {
          name: "application-name",
          content: "Aplaer Website",
        },
      ]}
      additionalLinkTags={[
        {
          rel: "icon",
          href: `/favicon.ico`,
        },
        {
          rel: "apple-touch-icon",
          href: `/apple-touch-icon.png`,
          sizes: "180x180",
        },
      ]}
      openGraph={{
        type: "website",
        locale: "ca",
        url: `${BASE_URL}${asPath}`,
        site_name: "Aplaer",
        title: "Aplaer",
        description:
          "Aplaer és un projecte d'educació sexual integral d'acompanyament a les sexualitats. Oferim espais de debat i coneixement vers la salut sexual i reproductiva. Treballem des d'una perspectiva feminista posant al centre el plaer i les cures. Desenvolupem tallers i formacions dirigides a infants, joves, famílies i comunitat educativa. A través de la vivencialitat i la participació apostem per ampliar la mirada de la vivència sexual des de la diversitat i la coeducació.",
      }}
    />
  );
};
